import React from 'react';
import { SummaryContactCard } from '@fingo/lib/components/cards';
import Grid from '@mui/material/Grid';
import { COLLECTION_PURCHASE_ORDERS } from '@fingo/lib/graphql/collection/query';
import { ArrayOfId, MasterEntityType } from '@fingo/lib/propTypes';
import { CONTACT_TYPES } from '@fingo/lib/constants';
import DebtorActions from './DebtorActions';
import DebtorOrderingSummaryStatistics from './DebtorOrderingSummaryStatistics';

const DebtorOrderingSummary = ({ debtor, selectedDocumentIds }) => (
  <Grid
    container
    id="debtor-summary"
    direction="row"
    bgcolor="background.light"
    justifyContent="center"
    alignItems="stretch"
    padding={1}
    columns={10}
    width="100%"
    borderRadius={2}
  >
    <Grid
      item
      xs={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <SummaryContactCard
        contactType={CONTACT_TYPES.COLLECTION}
        showAllContactTypes
        masterEntity={debtor}
      />
    </Grid>
    <Grid
      item
      xs={6}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <DebtorOrderingSummaryStatistics debtor={debtor} />
    </Grid>
    <Grid
      item
      xs={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <DebtorActions
        selectedDocumentIds={selectedDocumentIds}
        debtor={debtor}
        query={COLLECTION_PURCHASE_ORDERS}
      />
    </Grid>
  </Grid>
);

DebtorOrderingSummary.propTypes = {
  selectedDocumentIds: ArrayOfId.isRequired,
  debtor: MasterEntityType.isRequired,
};

export default DebtorOrderingSummary;
