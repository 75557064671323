import React from 'react';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetUser } from '@fingo/lib/hooks';

const PaymentPlanActions = () => {
  const history = useHistory();
  const user = useGetUser();
  return (
    user?.isSuperuser ? (
      <Button
        variant="contained"
        size="small"
        onClick={() => history.push('create-amortization-schedule')}
      >
        <Add sx={{ fontSize: 18 }} />
        Crear plan de pago
      </Button>
    ) : <></>
  );
};

export default PaymentPlanActions;
