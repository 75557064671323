import { useQuery } from '@apollo/client';
import { FINGO_MASTER_ENTITY_ID } from '@fingo/lib/constants';
import { defaultCurrency } from '@fingo/lib/helpers/money';
import { useGetCountryFromUrl } from '@fingo/lib/hooks';
import { useMemo } from 'react';
import { COLLECTION_SUMMARY } from '../graphql/queries';

const useCollectionSummary = () => {
  const country = useGetCountryFromUrl();
  const selectedCurrency = defaultCurrency[country?.name];
  const { data, loading } = useQuery(COLLECTION_SUMMARY, {
    variables: { ownerId: FINGO_MASTER_ENTITY_ID,
      product: 'Lending',
      currency: selectedCurrency,
    },
  });
  const collectionManagersByPriority = useMemo(() => {
    const collectionPriorities = [
      ...(data?.factoringCollectionManagersByPriority?.map(
        ({ collectionPriority }) => collectionPriority,
      ) || []),
      ...(data?.orderingCollectionManagersByPriority?.map(
        ({ collectionPriority }) => collectionPriority,
      ) || []),
    ];
    const uniqueCollectionPriorities = [...new Set(collectionPriorities)];

    return uniqueCollectionPriorities.map((collectionPriority) => {
      const factoringCollectionManagers = data?.factoringCollectionManagersByPriority?.find(
        (fcmbp) => fcmbp.collectionPriority === collectionPriority,
      );
      const orderingCollectionManagers = data?.orderingCollectionManagersByPriority?.find(
        (ocmbp) => ocmbp.collectionPriority === collectionPriority,
      );
      const factoringAmount = factoringCollectionManagers?.amount || 0;
      const orderingAmount = orderingCollectionManagers?.amount || 0;
      const factoringDebtors = factoringCollectionManagers?.debtors || 0;
      const orderingDebtors = orderingCollectionManagers?.debtors || 0;
      return {
        collectionPriority,
        amount: factoringAmount + orderingAmount,
        debtors: factoringDebtors + orderingDebtors,
        factoringCount: factoringCollectionManagers?.count || 0,
        orderingCount: orderingCollectionManagers?.count || 0,
      };
    });
  }, [data]);

  const collectionManagersByDateRange = useMemo(() => {
    const dateGroups = [
      ...(data?.factoringCollectionManagersByDateRange?.map(
        ({ dateGroup }) => dateGroup,
      ) || []),
      ...(data?.orderingCollectionManagersByDateRange?.map(
        ({ dateGroup }) => dateGroup,
      ) || []),
    ];

    const uniqueDateGroups = [...new Set(dateGroups)];

    return uniqueDateGroups.map((dateGroup) => {
      const factoringDateGroup = data?.factoringCollectionManagersByDateRange?.find(
        (fcmbdr) => fcmbdr.dateGroup === dateGroup,
      );
      const orderingDateGroup = data?.orderingCollectionManagersByDateRange?.find(
        (ocmbdr) => ocmbdr.dateGroup === dateGroup,
      );
      const allCollectionPriorities = [
        ...(factoringDateGroup?.values.map(({ priority }) => priority) || []),
        ...(orderingDateGroup?.values.map(({ priority }) => priority) || []),
      ];
      const uniqueCollectionPriorities = [...new Set(allCollectionPriorities)];

      return {
        dateGroup,
        values: uniqueCollectionPriorities.map((collectionPriority) => {
          const factoringCollectionPriority = factoringDateGroup?.values.find(
            ({ priority }) => priority === collectionPriority,
          );
          const orderingCollectionPriority = orderingDateGroup?.values.find(
            ({ priority }) => priority === collectionPriority,
          );
          const factoringAmount = factoringCollectionPriority?.amount || 0;
          const orderingAmount = orderingCollectionPriority?.amount || 0;
          const factoringCount = factoringCollectionPriority?.count || 0;
          const orderingCount = orderingCollectionPriority?.count || 0;

          return {
            amount: factoringAmount + orderingAmount,
            count: factoringCount + orderingCount,
            priority: collectionPriority,
          };
        }),
      };
    });
  }, [data]);

  const collectionManagersByExpiration = useMemo(() => {
    const {
      factoringCollectionManagersByExpiration = {
        unexpiredAmount: 0,
        expiredAmount: 0,
        unexpiredCount: 0,
        expiredCount: 0,
      },
      orderingCollectionManagersByExpiration = { expiredAmount: 0, unexpiredAmount: 0 },
    } = data || {};

    return {
      expired:
        factoringCollectionManagersByExpiration.expiredAmount
        + orderingCollectionManagersByExpiration.expiredAmount,
      unexpiredAmount:
        factoringCollectionManagersByExpiration.unexpiredAmount
        + orderingCollectionManagersByExpiration.unexpiredAmount,
      expiredCount: factoringCollectionManagersByExpiration.expiredCount,
      unexpiredCount: factoringCollectionManagersByExpiration.unexpiredCount,
    };
  }, [data]);

  return {
    collectionManagersByPriority,
    collectionManagersByDateRange,
    collectionManagersByExpiration,
    loading,
  };
};

export default useCollectionSummary;
