import React from 'react';
import { DocumentList } from '@fingo/lib/components/lists';
import { useInvoicePreColumns, useInvoiceLucilaCustomColumns, FINGO_MASTER_ENTITY_ID } from '@fingo/lib/constants';
import { EXPORT_INVOICES, INVOICES_IN_COLLECTION } from '@fingo/lib/graphql';

const CollectionInvoices = () => (
  <DocumentList
    trackerId="COLLECTION_INVOICES"
    type="collection-invoices-list"
    headerTitle="Lista de facturas"
    countryFilter
    queryDocument={INVOICES_IN_COLLECTION}
    initialOrderBy="collectionManagerRelation_CollectionPriority_Value"
    includeHeaders={[
      'folio-invoice-profile',
      'dateIssued',
      'receiver_Name',
      'company_profile',
      'amountWithIvaAndCreditNote',
      'dateToPay',
      'agreedDateToPay',
      'collectionManagerRelation_Collector',
      'siiStatus',
      'collectionManagerRelation_Priority',
      'ratificationActions',
      'collectionManagerRelation_CollectionPriority_Value',
      'actionsDrawer',
    ]}
    mobileHeaders={['folio', 'receiver_Name', 'cessionStatus']}
    allCompaniesOptions
    getRowId={(row) => row.id}
    queryExport={EXPORT_INVOICES}
    showExportInvoice
    showFilters
    defaultFilterProps={{
      showQuickFilters: true,
      buttonsToShow: ['requestingPlatform'],
      showValidSiiCredentialsFilter: false,
      showLightningFilter: false,
      showPreoffersFilters: false,
      showStatesFilter: false,
      showDatesFilter: false,
      showRefresh: true,
      showCompanyIssuerFilter: false,
    }}
    customVariables={{
      receiver_Rut: null,
      receiverId: null,
      inCollection: FINGO_MASTER_ENTITY_ID,
      dateIssued_Gte: null,
      dateIssued_Lte: null,
      status: ['Debt', 'Transfered'],
    }}
    initialPageSize={50}
    preColumns={useInvoicePreColumns(useInvoiceLucilaCustomColumns(false))}
  />
);

export default CollectionInvoices;
