import React from 'react';
import PropTypes from 'prop-types';
import { MasterEntityType } from '@fingo/lib/propTypes';
import { useQuery } from '@apollo/client';
import { ORDERING_DEBTOR_SUMMARY } from '@fingo/lib/graphql';
import DebtorOrderingSummaryStatisticsGrid from '@fingo/lib/features/debtor/DebtorOrderingSummaryStatisticsGrid';

const DebtorOrderingSummaryStatistics = ({ debtor }) => {
  const { data, loading } = useQuery(ORDERING_DEBTOR_SUMMARY, {
    variables: { debtorId: debtor?.id },
    skip: !debtor,
  });
  const summary = Object.values(data || [])?.[0] || {};
  const debtorType = debtor?.debtor?.classification.code;

  return (
    <DebtorOrderingSummaryStatisticsGrid
      debtorType={debtorType}
      currentAmount={summary?.currentAmount}
      debtAmount={summary?.debtAmount}
      loading={loading}
      currentValid={summary?.currentValid}
    />
  );
};

DebtorOrderingSummaryStatistics.propTypes = {
  debtor: PropTypes.shape(MasterEntityType).isRequired,
};

export default DebtorOrderingSummaryStatistics;
