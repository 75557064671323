import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import RoundedWhiteBox from '@fingo/lib/components/boxes/RoundedWhiteBox';
import { GoBackButton } from '@fingo/lib/components/buttons';
import CompanyFilter from '@fingo/lib/components/filters/CompanyFilter';
import Add from '@mui/icons-material/Add';
import InsertEmoticon from '@mui/icons-material/InsertEmoticon';
import sum from 'lodash/sum';
import toInteger from 'lodash/toInteger';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { all } from 'underscore';
import { CREATE_PAYMENT_PLAN } from '@fingo/lib/graphql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { useSnackBars } from '@fingo/lib/hooks';
import moment from 'moment';
import { formatGraphQlDate, formatMoneyWithSign } from '@fingo/lib/helpers';
import { MoneyInput } from '@fingo/lib/components/inputs';
import { GET_PAYMENT_PLAN } from '@fingo/lib/graphql/payment_plans/query';
import { InstallmentRow } from '../components/PaymentPlans';
import InstallmentCreationHelper from '../components/PaymentPlans/InstallmentCreationHelper';

const CreatePaymentPlan = () => {
  const emptyInstallment = {
    amortizationToPay: 0,
    paymentDate: moment().startOf('day'),
  };
  const location = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(location.search);
  const [openHelper, setOpenHelper] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [recalculateInstallments, setRecalculateInstallments] = useState(false);
  const [targetAmount, setTargetAmount] = useState(urlParams.get('targetAmount') || 1000000);
  const [interestRate, setInterestRate] = useState(1);
  const [defaultRate, setDefaultRate] = useState(1);
  const [installments, _setInstallments] = useState([emptyInstallment]);
  const areAllInstallmentsOk = useMemo(() => all(installments.map((
    { amortizationToPay, paymentDate },
  ) => (amortizationToPay && paymentDate))), [installments]);
  const isButtonDisabled = useMemo(
    () => (!defaultRate || !interestRate || !areAllInstallmentsOk || !selectedCompany),
    [defaultRate, interestRate, areAllInstallmentsOk, selectedCompany],
  );
  const { addAlert } = useSnackBars();
  const [
    _createPaymentPlan, { loading: loadingCreation },
  ] = useMutation(CREATE_PAYMENT_PLAN, {
    onError: () => {
      addAlert({
        id: 'new-leads',
        message: 'Ha ocurrido algún error',
      });
    },
    onCompleted: () => {
      addAlert({
        id: 'new-leads',
        message: 'Plan creado con éxito',
      });
      history.push('/app/treasury/conciliation');
    },
  });
  const createPaymentPlan = () => {
    let runningDate = installments[0]?.paymentDate;
    const parsedInstallments = installments.map(
      ({ amortizationToPay, paymentDate }) => {
        const intallmentStartingDate = runningDate;
        runningDate = paymentDate;
        return {
          amount: amortizationToPay,
          startingDate: formatGraphQlDate(intallmentStartingDate),
          expirationDate: formatGraphQlDate(paymentDate),
        };
      },
    );
    _createPaymentPlan({ variables: {
      masterEntityId: selectedCompany?.id,
      defaultRate,
      interestRate,
      installments: parsedInstallments,

    } });
  };
  const areAllInstallmentsEqual = useMemo(() => (
    installments.slice(1).every(
      ({ totalAmount }) => (
        toInteger(totalAmount) === toInteger(installments[1]?.totalAmount)
      ),
    )), [installments]);
  const [getPaymentPlan, { loading }] = useLazyQuery(GET_PAYMENT_PLAN, {
    onCompleted: ({ getPaymentPlan: data }) => {
      _setInstallments(
        data.map(({ amortizationToPay, paymentDate }) => ({
          amortizationToPay,
          paymentDate: moment(paymentDate, 'YYYY-MM-DD'),
        })),
      );
      setRecalculateInstallments((old) => !old);
    },
  });
  useEffect(() => {
    let partialSum = 0;
    let runningDate = installments[0]?.paymentDate;
    const newItems = installments.map(
      ({ paymentDate, amortizationToPay }) => {
        const remainingAmortization = targetAmount - partialSum;
        const term = paymentDate?.diff(runningDate, 'days') || 0;
        const interestAmount = (remainingAmortization * interestRate * term) / (100 * 30);
        const totalAmount = interestAmount + amortizationToPay;
        runningDate = paymentDate;
        partialSum += amortizationToPay;
        return {
          paymentDate,
          amortizationToPay,
          interestAmount,
          totalAmount,
        };
      },
    );
    _setInstallments(newItems);
  }, [recalculateInstallments]);
  return (
    <Box height="100%" width="100%" p={2} backgroundColor="background.dark">
      <RoundedWhiteBox height="100%" width="100%">
        <Stack direction="row" my={2} alignItems="center">
          <GoBackButton />
          <Typography variant="h1" color="primary.main">
            Crear plan de Pago
          </Typography>
        </Stack>
        <Stack direction="row" my={3} spacing={2}>
          <CompanyFilter
            size="small"
            sx={{ width: '320px' }}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
            companyFilterSetProps={{ inBlacklist: false }}
          />
          <MoneyInput
            size="small"
            label="Monto objetivo"
            value={targetAmount}
            sx={{ width: '180px' }}
            setValue={setTargetAmount}
          />
          <TextField
            size="small"
            label="Tasa de interés"
            value={interestRate}
            sx={{ width: '120px' }}
            onChange={({ target }) => setInterestRate(target.value)}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: { min: 0, max: 100, step: 0.01 },
            }}
          />
          <TextField
            size="small"
            type="number"
            label="Tasa de mora"
            variant="outlined"
            value={defaultRate}
            sx={{ width: '120px' }}
            onChange={({ target }) => setDefaultRate(target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: { min: 0, max: 100, step: 0.01 },
              padding: 0,
            }}
          />
        </Stack>
        <Divider />
        <Stack direction="row" alignItems="center" spacing={2} p={1}>
          <span style={{ width: '120px' }} />
          <Typography variant="subtitle2" width="180px">Fecha cuota</Typography>
          <Typography variant="subtitle2" width="180px">Capital de la cuota</Typography>
          <Typography variant="subtitle2" width="180px"> Monto del interés</Typography>
          <Typography variant="subtitle2" width="180px">Monto total cuota</Typography>
        </Stack>
        <Stack mb={1} ml={1}>
          {
              installments.map(({ amortizationToPay,
                paymentDate, interestAmount,
                totalAmount }, index) => (
                  <InstallmentRow
                    index={index}
                    amortizationToPay={amortizationToPay}
                    paymentDate={paymentDate}
                    _setInstallments={_setInstallments}
                    interestAmount={interestAmount}
                    totalAmount={totalAmount}
                    setRecalculateInstallments={setRecalculateInstallments}
                  />
              ))
            }

        </Stack>
        <Divider />
        <Stack direction="row" alignItems="center" spacing={2} p={2}>
          <Typography width="120px">
            <b>
              Totales:
            </b>
          </Typography>
          <span style={{ width: '180px' }} />
          <Typography width="180px">
            <b>
              {
              formatMoneyWithSign(
                sum(installments.map(({ amortizationToPay }) => amortizationToPay)),
              )
            }
            </b>
          </Typography>
          <Typography width="180px">
            <b>
              {
              formatMoneyWithSign(
                sum(installments.map(({ interestAmount }) => interestAmount)),
              )
            }
            </b>
          </Typography>
          <Typography width="180px">
            <b>
              {
              formatMoneyWithSign(
                sum(installments.map(({ totalAmount }) => totalAmount)),
              )
            }
            </b>
          </Typography>
          {!areAllInstallmentsEqual
          && (
          <LoadingButton
            variant="outlined"
            loading={loading}
            onClick={() => getPaymentPlan({ variables: {
              principalAmount: targetAmount,
              monthlyRate: interestRate,
              upfrontAmount: installments[0]?.amortizationToPay,
              upfrontPaymentDate: formatGraphQlDate(installments[0]?.paymentDate),
              installmentDates: installments.slice(1).map(
                ({ paymentDate }) => formatGraphQlDate(paymentDate),
              ),
            } })}
          >
            Recalcular cuotas iguales
          </LoadingButton>
          )}
        </Stack>
        <Button
          onClick={() => _setInstallments((prev) => [...prev, emptyInstallment])}
        >
          <Add />
          Agregar cuota
        </Button>
        <Tooltip title="Asistente para cuotas">
          <IconButton onClick={() => setOpenHelper(true)}>
            <InsertEmoticon />
          </IconButton>
        </Tooltip>
        <br />
        <Stack justifyContent="end" alignItems="center">
          <LoadingButton
            size="small"
            variant="contained"
            sx={{ mr: 4, alignSelf: 'end' }}
            disabled={isButtonDisabled}
            loading={loadingCreation}
            onClick={createPaymentPlan}
          >
            Crear plan de pago
          </LoadingButton>
        </Stack>
      </RoundedWhiteBox>
      <InstallmentCreationHelper
        open={openHelper}
        setOpen={setOpenHelper}
        targetAmount={targetAmount}
        _setInstallments={_setInstallments}
        setRecalculateInstallments={setRecalculateInstallments}
        setTargetAmount={setTargetAmount}
        setInterestRate={setInterestRate}
      />
    </Box>
  );
};

export default CreatePaymentPlan;
