/* eslint-disable import/prefer-default-export */
export const collectionReceiverActionTypes = [
  { label: 'Llamado telefónico al receptor del documento', value: 'PHONE_CALL_TO_RECEIVER', enabled: 'debtor' },
  { label: 'Mail al receptor', value: 'MAIL_TO_RECEIVER', enabled: 'debtor' },
  { label: 'Envío formulario de pago al receptor', value: 'RECEIVER_FORM_MAIL', enabled: 'debtor' },
  { label: 'Agregar gestión genérica', value: 'OTHER', enabled: 'always' },
];

export const actionTypeGroups = {
  receiver: [
    'PHONE_CALL_TO_RECEIVER',
    'MAIL_TO_RECEIVER',
    'RECEIVER_FORM_MAIL',
  ],
  issuer: [
    'PHONE_CALL_TO_ISSUER',
    'MAIL_TO_ISSUER',
  ],
};
