import { formatMoney } from '@fingo/lib/helpers';
import React from 'react';
import { CollectionPriorityCell, DateWithDaysDiffCell } from '@fingo/lib/components/dataGridCells';
import { ActionCell, ActionDrawerCell, LinkCell } from '@fingo/lib/components/cells';
import DrawerHeader from '@fingo/lib/components/drawer/DrawerHeader';
import CollectionActions from '@fingo/lib/components/drawer/CollectionActions';

export const VIEW_TITLE = 'Lista de deudores';

export const SEARCH_PLACEHOLDER = 'Buscar deudor';

export const HEADERS = [
  'name',
  'documentsCount',
  'minDateToPay',
  'totalAmount',
  'debtor_CollectionPriorities_Priority_Value',
  'defaultRate',
  'lastAction',
  'actionsDrawer',
];

export const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100];

export const INITIAL_PAGE_SIZE = 25;

export const INITIAL_ORDER_BY = 'debtor_CollectionPriorities_Priority_Value';

export const DEFAULT_PRIORITY_SELECT_VALUE = { label: 'Todas', value: null };

export const COLUMNS = [
  {
    field: 'name',
    headerName: 'Deudor',
    sortable: true,
    filterable: false,
    flex: 1,
    renderCell: ({ row }) => (
      <LinkCell
        to={`/app/debtor/${row.id}`}
        label={row.name}
        caption={row.displayNationalIdentifier}
      />
    ),
  },
  {
    field: 'documentsCount',
    align: 'center',
    headerName: 'Cantidad de facturas',
    headerAlign: 'left',
    type: 'number',
    sortable: false,
    filterable: false,
    minWidth: 165,
    valueGetter: ({ row }) => row.documentsCount,
  },
  {
    field: 'minDateToPay',
    headerName: 'Mayor vencimiento',
    type: 'string',
    sortable: false,
    filterable: false,
    minWidth: 150,
    renderCell: ({ row }) => <DateWithDaysDiffCell date={row.olderExpiration} useColors />,
  },
  {
    field: 'totalAmount',
    headerName: 'Total en cobranza',
    type: 'string',
    sortable: false,
    filterable: false,
    minWidth: 145,
    valueGetter: ({ row }) => `${formatMoney(row.totalAmount)}`,
  },
  {
    field: 'debtor_CollectionPriorities_Priority_Value',
    headerName: 'Prioridad',
    headerAlign: 'left',
    sortable: true,
    filterable: false,
    minWidth: 125,
    renderCell: ({ row }) => <CollectionPriorityCell priority={row?.priority} />,
  },
  {
    field: 'lastAction',
    headerName: 'Última gestión',
    type: 'string',
    sortable: false,
    filterable: false,
    minWidth: 160,
    renderCell: ({ row }) => <ActionCell action={row.lastAction} />,
  },
  {
    field: 'actionsDrawer',
    headerName: 'Gestiones',
    type: 'string',
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <ActionDrawerCell
        title={row.name}
        subtitle={row.displayNationalIdentifier}
        actions={row.everyCollectionAction}
        headerComponent={DrawerHeader}
        contentComponent={CollectionActions}
      />
    ),
  },
];
