import React from 'react';
import { DocumentList } from '@fingo/lib/components/lists';
import { useOrderingLucilaPreColumns } from '@fingo/lib/constants';
import { ORDERING_PURCHASE_ORDERS } from '@fingo/lib/graphql';

const CollectionPurchaseOrders = () => (
  <DocumentList
    trackerId="COLLECTION_ORDERING"
    type="collection-debtor-profile"
    headerTitle="Lista de órdenes de compra"
    queryDocument={ORDERING_PURCHASE_ORDERS}
    allCompaniesOptions
    customVariables={{
      inCollection: true,
      receiver_Rut: null,
      receiverId: null,
      dateIssued_Gte: null,
      dateIssued_Lte: null,
    }}
    includeHeaders={[
      'orderNumber',
      'company_MasterEntity_Name',
      'purchaser_Name',
      'publicationDate',
      'totalAmount',
      'orderingoffer_OrderingFinancedAmount',
      'orderingoffer_InvoiceIssuedDate',
      'agreedDateToPay',
      'executiveAssigned',
      'collectionManagerRelation_Collector',
      'actionsDrawer',
    ]}
    mobileHeaders={['orderNumber', 'purchaser_Name', 'orderingOperationStatus']}
    initialOrderBy="collectionManagerRelation_CollectionPriority_Value"
    initialPageSize={100}
    getRowId={(row) => row.id}
    onCompletedSetLength={(data) => data.purchaseOrders.totalCount}
    onCompletedSetRows={(data) => data.purchaseOrders.edges.map((edge) => edge.node)}
    preColumns={useOrderingLucilaPreColumns()}
  />
);

export default CollectionPurchaseOrders;
