/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const COLLECTION_SUMMARY = gql`
  query CollectionSummary($ownerId: ID!, $product: String, $currency: String) {
    factoringCollectionManagersByPriority(ownerId: $ownerId, product: $product, currency: $currency) {
      count
      amount
      debtors
      collectionPriority
    }
    factoringCollectionManagersByExpiration(ownerId: $ownerId, product: $product, currency: $currency) {
      unexpiredAmount
      expiredAmount
      unexpiredCount
      expiredCount
    }
    factoringCollectionManagersByDateRange(ownerId: $ownerId, product: $product, currency: $currency) {
      dateGroup
      values {
        amount
        count
        priority
      }
    }
    orderingCollectionManagersByPriority(ownerId: $ownerId, currency: $currency) {
      count
      amount
      debtors
      collectionPriority
    }
    orderingCollectionManagersByExpiration(ownerId: $ownerId, currency: $currency) {
      expiredAmount
      unexpiredAmount
    }
    orderingCollectionManagersByDateRange(ownerId: $ownerId, currency: $currency) {
      dateGroup
      values {
        amount
        count
        priority
      }
    }
  }
`;
